import React, { useState } from 'react'
import { Section } from '../UI/Section'
import { Container } from '../UI/Grid'
import { Heading } from '../UI/Typography'
import ProductList from './ProductList'
import DefaultVector from '../Shared/DefaultVector'
import Element from '../UI/Element'
import Button from '../UI/Button'
import DownloadIcon from '../Icons/DownloadIcon'
import { graphql, useStaticQuery } from 'gatsby'
import { subCategory } from '../../templates/products'

const RfList = ({ data }) => {
  const pdf = useStaticQuery(graphql`
    query rfsPdfQuery {
      allFile(filter: { relativePath: { eq: "RF_and_Microwave_Modules.pdf" } }) {
        nodes {
          publicURL
        }
      }
    }
  `)

  // let rfs = data.reduce((acc, curr) => {
  //   if (!acc[curr.product_subcategory_id]) acc[curr.product_subcategory_id] = []
  //   acc[curr.product_subcategory_id].push(curr)
  //   return acc
  // }, {})
  // rfs = Object.entries(rfs)

  let rfs = data.reduce((acc, curr) => {
    acc[curr.subcategory] = acc[curr.subcategory] || []
    acc[curr.subcategory].push(curr)
    return acc
  }, {})
  rfs = Object.entries(rfs)

  return (
    <Section py={{ _: 16, lg: 0 }}>
      <DefaultVector>
        <Container pb={{ lg: 20 }}>
          <Heading textAlign="center" size="h2" mb={10}>
            RF and Microwave Modules
          </Heading>

          {rfs.map((r, idx) => (
            <Element key={idx} pb={10}>
              <Heading size="h4" textAlign="center" mb={6} mt={10} color="text">
                {r[0] !== 'null' ? r[0] : ''}
              </Heading>
              <ProductList products={r[1]} />
            </Element>
          ))}

          <Heading size="h6" textAlign="center" mb={6} mt={10}>
            If you want to see the summary of RF and Microwave Modules, click the download button:
          </Heading>
          <Element textAlign="center" mb={10}>
            <Button
              variant="brand-outline"
              as="a"
              href={pdf.allFile.nodes[0].publicURL}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Element mr={2}>Download</Element>
              <DownloadIcon />
            </Button>
          </Element>
        </Container>
      </DefaultVector>
    </Section>
  )
}

export default RfList
