import React, { useState } from 'react'
import Site from '../components/Layout/Site'
import { graphql, Link } from 'gatsby'
import { Row, Col, Container } from '../components/UI/Grid'
import { Heading } from '../components/UI/Typography'
import ScaledImage from '../components/UI/ScaledImage'
import { Section } from '../components/UI/Section'
import Seo from '../components/Layout/Seo'
import styled from 'styled-components'
import Element from '../components/UI/Element'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Card } from '../components/UI/Card'
import Button from '../components/UI/Button'
import DefaultVector from '../components/Shared/DefaultVector'
import { Field, FieldContainer, FieldGroup } from '../components/UI/Forms'
import { Subtitle, Title } from '../components/Shared/SectionHeader.styles'
import { Tab, Tabs } from 'react-bootstrap'

export const query = graphql`
  query ($slug: String!) {
    productsJson(slug: { eq: $slug }) {
      product_id
      name
      product_category
      product_subcategory
      image {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], layout: FULL_WIDTH)
        }
      }
      product_specs {
        name
        body_size
        die_attach
        lead_count
        lead_finish
        leadframe_material
        marking
        mold_compound
        packaging
        capability
        pad_size
        wirebond
        major_assembly_process
        test_parameters
        frequency_range
      }
    }
  }
`

export const subCategory = category => {
  switch (category) {
    case 1:
      return 'Signal Conditioning / Control'
    case 2:
      return 'Time and Frequency'
    case 3:
      return 'Module Assembly Packaging'
    case 4:
      return 'Passive'
    default:
      return null
  }
}

const ProductDetailsPage = ({ data, pageContext }) => {
  const [activeTab, setActiveTab] = useState('SOT227 LF1')
  const product = data.productsJson
  const name = product.product_specs.filter(i => i.name !== null)
  const leadCount = product.product_specs.filter(i => i.lead_count !== null)
  const padSize = product.product_specs.filter(i => i.pad_size !== null)
  const leadframeMaterial = product.product_specs.filter(i => i.leadframe_material !== null)
  const dieAttach = product.product_specs.filter(i => i.die_attach !== null)
  const wirebond = product.product_specs.filter(i => i.wirebond !== null)
  const bodySize = product.product_specs.filter(i => i.body_size !== null)
  const moldCoumpound = product.product_specs.filter(i => i.mold_compound !== null)
  const marking = product.product_specs.filter(i => i.marking !== null)
  const leadFinish = product.product_specs.filter(i => i.lead_finish !== null)
  const packaging = product.product_specs.filter(i => i.packaging !== null)
  const capability = product.product_specs.filter(i => i.capability !== null)
  const majorAssemblyProcess = product.product_specs.filter(i => i.major_assembly_process !== null)
  const testParameters = product.product_specs.filter(i => i.test_parameters !== null)
  const frequencyRange = product.product_specs.filter(i => i.frequency_range !== null)

  return (
    <Site>
      <Seo title={product.name} />
      <Section py={{ _: 16, lg: 0 }}>
        <DefaultVector>
          <Container pb={20}>
            <Row mb={15}>
              <Col col={{ lg: 6 }} order={{ lg: 9999 }}>
                <Subtitle size="caption" mb={2}>
                  {product.product_category === 1
                    ? 'Power Semiconductors'
                    : 'RF and Microwave Modules'}
                </Subtitle>
                {product.product_subcategory && (
                  <Title mb={1}>{subCategory(product.product_subcategory)}</Title>
                )}
                <Heading as="h1" size="h2" color="#000">
                  {product.name}
                  {product.product_subcategory === 4 && (
                    <>
                      <br />
                      Co-Axial, Drop In, Surface Amount
                    </>
                  )}
                </Heading>
                <Element pt={10}>
                  <Button
                    variant="brand"
                    as={Link}
                    to="/contact-us"
                    state={{
                      productId: product.product_id,
                      inquiryType: 'Customers',
                    }}
                  >
                    Inquire Now
                  </Button>
                </Element>
              </Col>
              <Col col={{ lg: 6 }}>
                <Card bordered cardhidden="true">
                  <ScaledImage scaleHeight={282} scaleWidth={534}>
                    <GatsbyImage image={getImage(product.image)} alt={product.name} />
                  </ScaledImage>
                </Card>
              </Col>
            </Row>

            {name.length > 0 ? (
              <>
                <FieldGroup>
                  <FieldContainer>
                    <Field as="select" onChange={e => setActiveTab(e.target.value)}>
                      {name.map((ps, idx) => (
                        <option value={ps.name}>{ps.name}</option>
                      ))}
                    </Field>
                  </FieldContainer>
                </FieldGroup>

                <Tabs id="controlled-tab-example" activeKey={activeTab} className="mb-3">
                  {name.map((ps, idx) => (
                    <Tab value={ps.name} key={idx} eventKey={ps.name}>
                      <Table>
                        {ps.lead_count && (
                          <tr>
                            <td>Lead Count</td>
                            <td>{ps.lead_count}</td>
                          </tr>
                        )}
                        {ps.pad_size && (
                          <tr>
                            <td>Pad Size</td>
                            <td dangerouslySetInnerHTML={{ __html: ps.pad_size }}></td>
                          </tr>
                        )}
                        {ps.leadframe_material && (
                          <tr>
                            <td>Leadframe Material</td>
                            <td>{ps.leadframe_material}</td>
                          </tr>
                        )}
                        {ps.die_attach && (
                          <tr>
                            <td>Die Attach</td>
                            <td>{ps.die_attach}</td>
                          </tr>
                        )}
                        {ps.wirebond && (
                          <tr>
                            <td>Wirebond</td>
                            <td>{ps.wirebond}</td>
                          </tr>
                        )}
                        {ps.body_size && (
                          <tr>
                            <td>Body Size</td>
                            <td dangerouslySetInnerHTML={{ __html: ps.body_size }}></td>
                          </tr>
                        )}
                        {ps.mold_compound && (
                          <tr>
                            <td>Mold Compound</td>
                            <td>{ps.mold_compound}</td>
                          </tr>
                        )}
                        {ps.marking && (
                          <tr>
                            <td>Marking</td>
                            <td>{ps.marking}</td>
                          </tr>
                        )}
                        {ps.lead_finish && (
                          <tr>
                            <td>Lead Finish</td>
                            <td>{ps.lead_finish}</td>
                          </tr>
                        )}
                        {ps.packaging && (
                          <tr>
                            <td>Packaging</td>
                            <td>{ps.packaging}</td>
                          </tr>
                        )}
                      </Table>
                    </Tab>
                  ))}
                </Tabs>
              </>
            ) : (
              <Table>
                {leadCount?.length > 0 && (
                  <tr>
                    <td>Lead Count</td>
                    {leadCount.map((lc, idx) => (
                      <td>{lc.lead_count}</td>
                    ))}
                  </tr>
                )}
                {padSize?.length > 0 && (
                  <tr>
                    <td>Pad Size</td>
                    {padSize.map((lc, idx) => (
                      <td dangerouslySetInnerHTML={{ __html: lc.pad_size }}></td>
                    ))}
                  </tr>
                )}
                {leadframeMaterial?.length > 0 && (
                  <tr>
                    <td>Leadframe Material</td>
                    {leadframeMaterial.map((lc, idx) => (
                      <td>{lc.leadframe_material}</td>
                    ))}
                  </tr>
                )}
                {dieAttach?.length > 0 && (
                  <tr>
                    <td>Die Attach</td>
                    {dieAttach.map((lc, idx) => (
                      <td>{lc.die_attach}</td>
                    ))}
                  </tr>
                )}
                {wirebond?.length > 0 && (
                  <tr>
                    <td>Wirebond</td>
                    {wirebond.map((lc, idx) => (
                      <td>{lc.wirebond}</td>
                    ))}
                  </tr>
                )}
                {bodySize?.length > 0 && (
                  <tr>
                    <td>Body Size</td>
                    {bodySize.map((lc, idx) => (
                      <td dangerouslySetInnerHTML={{ __html: lc.body_size }}></td>
                    ))}
                  </tr>
                )}
                {moldCoumpound?.length > 0 && (
                  <tr>
                    <td>Mold Compound</td>
                    {moldCoumpound.map((lc, idx) => (
                      <td>{lc.mold_compound}</td>
                    ))}
                  </tr>
                )}
                {marking?.length > 0 && (
                  <tr>
                    <td>Marking</td>
                    {marking.map((lc, idx) => (
                      <td>{lc.marking}</td>
                    ))}
                  </tr>
                )}
                {leadFinish?.length > 0 && (
                  <tr>
                    <td>Lead Finish</td>
                    {leadFinish.map((lc, idx) => (
                      <td>{lc.lead_finish}</td>
                    ))}
                  </tr>
                )}
                {packaging?.length > 0 && (
                  <tr>
                    <td>Packaging</td>
                    {packaging.map((lc, idx) => (
                      <td>{lc.packaging}</td>
                    ))}
                  </tr>
                )}
                {capability?.length > 0 && (
                  <tr>
                    <td>Capability</td>
                    {capability.map((lc, idx) => (
                      <td>{lc.capability}</td>
                    ))}
                  </tr>
                )}
                {majorAssemblyProcess?.length > 0 && (
                  <tr>
                    <td>Major Assembly Process</td>
                    {majorAssemblyProcess.map((lc, idx) => (
                      <td>{lc.major_assembly_process}</td>
                    ))}
                  </tr>
                )}
                {testParameters?.length > 0 && (
                  <tr>
                    <td>Test Parameters</td>
                    {testParameters.map((lc, idx) => (
                      <td>{lc.test_parameters}</td>
                    ))}
                  </tr>
                )}
                {frequencyRange?.length > 0 && (
                  <tr>
                    <td>Frequency Range</td>
                    {frequencyRange.map((lc, idx) => (
                      <td>{lc.frequency_range}</td>
                    ))}
                  </tr>
                )}
              </Table>
            )}
          </Container>
        </DefaultVector>
      </Section>
    </Site>
  )
}

const Table = styled.table`
  width: 100%;
  table-layout: fixed;

  tr {
    td:first-child {
      width: 25%;
      font-weight: bold;
    }

    td {
      border-top: 1px solid #9e9e9e;
      border-left: 1px solid #9e9e9e;
      padding: 10px 8px;

      &:last-child {
        border-right: 1px solid #9e9e9e;
      }
    }

    &:last-child td {
      border-bottom: 1px solid #9e9e9e;
    }
  }
`

export default ProductDetailsPage
