import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Container } from 'styled-bootstrap-grid'
import Seo from '../../components/Layout/Seo'
import Site from '../../components/Layout/Site'
import RfBanner from '../../components/Products/RfBanner'
import RfList from '../../components/Products/RfList'
import { Section } from '../../components/UI/Section'

const RfMicrowaveModules = () => {
  const [processing, setProcessing] = useState(false)
  const [products, setProducts] = useState(null)
  const [staging, setStaging] = useState(false)

  const isBrowser = typeof window !== 'undefined'

  useEffect(() => {
    setProcessing(true)

    axios
      .get(
        `${
          isBrowser && window?.location?.origin === 'https://fastech.digiteer.dev'
            ? process.env.GATSBY_STAGING_API_URL
            : process.env.GATSBY_API_URL
        }/products?category=2`,
      )
      .then(res => {
        setProducts(res.data)
      })
      .finally(() => setProcessing(false))
  }, [])

  if (processing) {
    return null
  }

  return (
    <Site>
      <Seo title="RF and Microwave Modules" />
      <RfBanner />
      {products?.length > 0 ? (
        <RfList data={products} />
      ) : (
        <Section textAlign="center">
          <Container>
            <p>No existing RF and Microwave Modules.</p>
          </Container>
        </Section>
      )}
    </Site>
  )
}

export default RfMicrowaveModules
